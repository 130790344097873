export default {
  LIST_MANAGEMENT_ACCIDENT: 'Accident/accidents',
  LIST_ACCIDENT_WORKER: 'Accident/list-accident-worker',
  LIST_ACCIDENT_FILE: 'Accident/list-accident-file',
  DOWN_ACCIDENT_FILE: 'Accident/download-accident-file',
  APPROVE_ACCIDENT: 'Accident/approve-accident',
  REJECT_ACCIDENT: 'Accident/reject-accident',
  DOWNLOAD_ACCIDENT: 'Accident/download-file',
  DELETE_ACCIDENT: 'Accident/delete-accident',
}
